const newLocal = 'participate';
export const list  = [
    {
      title: 'Stake your $FCC',
      subtitle:'Reward $Fcc.',
      icon:{
        stakeCoin:'assets/images/icon/icon_bnb.png',
        rewardCoin:'assets/images/icon/icon_bnb.png'
      },
      apy:{
        label:'APY 130%',
        value:'13%',
      },
      totalStake:{
        label:'Total Stake',
        value:'100.000 BNB'
      },
      totalStaker:{
        label:'Total Staker',
        value:'410'
      },
      contract:'0xdc5b236981ff22955eadd09e46d54821deb00331',
      link: '/staking/0xdc5b236981ff22955eadd09e46d54821deb00331',
      status:'participate'
    },
    
  ];
  