export const periodeList  = [
    {
      label: '4 Week',
      duration:'30d',
    },
    {
      label: '12 Weeks',
      duration:'90d',
    },
    {
      label: '5 Months',
      duration:'150d',
    },
    {
      label: '12 Months',
      duration:'365d',
    },
  ];
  